.terms-conditions-modal {
  width: 100% !important;
  max-width: 720px !important;

  ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;

    li {
      display: table;
      counter-increment: item;
      margin-bottom: 0.6em;
      font-weight: bold;

      &:before {
        content: counters(item, ".") ". ";
        display: table-cell;
        padding-right: 0.6em;
      }

      li {
        margin: 0;
        font-weight: normal;

        &:before {
          content: counters(item, ".") " ";
        }
      }

      p {
        font-weight: normal;
      }
    }
  }
}
@primary-color: #1178FF;@font-size-base: 16px;@border-radius-base: 20px;