
[id^="post-export-pdf-"] {

    [id^="banner-export-"] {
        position: relative;
        width: 100%;
        height: 600px;

        #image-export {
            width: 100%;
            height: 100%;
            position: relative;
            background-position: 30% 30%;
            background-size: cover;
            background-repeat: no-repeat !important;
            text-align: left;

            .banner__darken-bg {
                height: 600px;
                background-color: rgba(0, 0, 0, 0.35);
                position: absolute;
                z-index: 1;
                background: linear-gradient(85.27deg, rgba(0, 0, 0, 0.8) 0%, rgba(45, 45, 45, 0.48) 100%) !important;
            }
        }
    }

    #category-export {
        span {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 8px 24px;
            gap: 10px;
            background-color: #ffffff;
            border: 1px solid #2D2D2D;
            border-radius: 100px;
            font-size: 15px;
        }
    }
}


@primary-color: #1178FF;@font-size-base: 16px;@border-radius-base: 20px;