@import "../../styles/variables.less";

.login-modal {
  max-width: 412px !important;
  width: 100% !important;
  top: 50% !important;
  transform: translateY(-50%) !important;

  .ant-modal {
    &-content {
      padding: 32px !important;
      border-radius: @radius !important;
    }

    &-footer {
      button {
        color: @black-80;

        &.ant-btn-link:not(:disabled):hover {
          color: @black !important;
        }
      }
    }
  }

  &--title {
    color: @black;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    display: block;
  }

  &--form {
    margin-top: 24px;

    &--item {
      &--submit-btn {
        background: @black-gradient;
        width: 100%;
        border: none;
        box-shadow: none;
        color: @white;
      }
    }
  }
}

@primary-color: #1178FF;@font-size-base: 16px;@border-radius-base: 20px;