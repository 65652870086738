@import "../../../styles/variables.less";

.external-articles-tab {
  max-height: 50vh;
  overflow-y: auto;

  .form-col {
    margin-right: 0 !important;
    padding-top: 8px;

    button {
      background: @black-gradient;
      box-shadow: none;
      border: none;
    }
  }

  &-table {
    .ant-table-thead .ant-table-cell {
      background-color: @black-5 !important;
    }

    th,
    td {
      text-align: center !important;
      border: none !important;
      color: @black !important;
    }

    th:before {
      background-color: @black-80 !important;
    }

    &-loading {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: -24px;
      width: 100%;
    }
  }
}

@primary-color: #1178FF;@font-size-base: 16px;@border-radius-base: 20px;