@import "../../styles/variables.less";

.ant-layout-footer {
  z-index: 3;
  width: 100%;
  position: relative;
  padding: 40px 20px;
  background: #ECECEC !important;

  &__wrapper {
    width: 100%;
    max-width: @site-max-width;
    margin: auto;

    a {
      color: black !important;
      margin-left: 32px;
      font-size: 14px;
    }

    span {
      font-size: 14px;
      color: black !important;
    }
    .image-co-financing {
      img {
        max-width: 800px;
      }
    }
  }

  hr {
    border: none !important;
    width: 100%;
    height: 0.8px;
    // background-color: black;
    margin: 24px auto;
    max-width: @site-max-width;
  }

  @media only screen and (max-width: @header-mobile-width) {
    text-align: center;
    padding: 20px 0 10px;
    > div:last-child {
      padding: 0 20px;
    }
  }
}

@primary-color: #1178FF;@font-size-base: 16px;@border-radius-base: 20px;