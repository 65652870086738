@import "../../../styles/variables.less";

.create-post {
  margin: 40px 9px !important;
  padding: 0 calc((100% - 1200px) / 2);

  .ant-form {
    width: 100% !important;
  }

  .image {
    background-color: #fff !important;
    padding: 32px;
    border-radius: @radius;

    .ant-upload {
      width: 100% !important;
      height: 172px !important;
      border: none !important;

      div {
        height: 172px;
        width: 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }



  .trl {
    margin-bottom: 20px;
    .ant-form-item-row {
      display: flex;
      flex-direction: column;

      .ant-form-item-label {
        padding-bottom: 25px;

        label {
          white-space: initial;
          text-align: start;
          line-height: initial;
          align-items: first baseline;
        }
      }
      .ant-form-item-control {
        margin-bottom: 25px;
      }
    }
  }


}

.ant-upload img {
  max-width: 100%;
  max-height: 100%;
}

.classification {
  background-color: #fff;
  margin-top: 32px;
  padding: 32px 20px;
  border-radius: @radius;

  .category {
    display: flex;
    justify-content: end;

    &--item {
      width: 100% !important;

      .select-category {
        .ant-row.ant-form-item-row {
          display: flex;
          justify-content: space-between;

          .ant-form-item-label {
            text-align: left;

            &>label {
              height: auto !important;
              text-wrap: wrap;
              align-items: baseline;
            }
          }

          .ant-form-item-control {
            max-width: 80px;
          }
        }
      }
    }
  }

  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 15px;
  }

  label {
    font-style: normal;
    font-weight: 400;
    line-height: 32px;

    &::after {
      display: none;
    }
  }

  .buuton-submit {
    width: 100% !important;
  }

  &--form-item {
    label {
      color: @black-80  !important;
    }

    span {
      color: @black  !important;
    }

    &--upload--loading {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &--switch {
      &[aria-checked="false"] {
        background: transparent !important;
        outline: 1px solid #d9d9d9;

        .ant-switch-handle::before {
          background: @black-gradient;
        }
      }

      &[aria-checked="true"] {
        background: @black-gradient  !important;
        outline: 1px solid #d9d9d9;

        .ant-switch-handle::before {
          background: @white;
        }
      }
    }

    &--submit {
      border: none;
      background: @black-gradient;
      box-shadow: none;

      span {
        color: @white  !important;
      }
    }

    &--checkbox {
      line-height: 32px;
      margin-bottom: 4px;
    }
  }
}

.ant-select-item-option-selected {
  background-color: #f3f3f3 !important;
}
@primary-color: #1178FF;@font-size-base: 16px;@border-radius-base: 20px;