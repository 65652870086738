@import "./variables.less";

* {
  margin: 0;
  padding: 0;
  height: auto;
}

body {
  height: auto;
  background: #ffffff;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

hr {
  background-color: @black-20;
  height: 1px;
  border: none;
}
@primary-color: #1178FF;@font-size-base: 16px;@border-radius-base: 20px;