@import "../../../styles/variables.less";

.post-list {
  width: 100%;
  max-width: @site-max-width;
  margin: 64px auto !important;
  @media only screen and (max-width: 1412px) {
    margin: 64px 20px !important;
}
@media only screen and (min-width: 1220px) {
  max-width: 1400px;
}

  &--col-left {
    padding-left: 0 !important;
  }

  &--col-right {
    padding-right: 0 !important;
  }

  &--title {
    color: @black;
    font-size: 24px;
    font-weight: bold;
  }

  &--not-found {
    display: block;
    margin-top: 50px;
  }

  &--list {
    margin-top: 32px;
    display: flex;
    flex-wrap: wrap;

    &--col {
      width: 340px;
      animation-name: card-animation;
      animation-duration: 0.8s;
      animation-timing-function: ease-in;
      animation-iteration-count: 1;

      @keyframes card-animation {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }

    &--spin {
      .ant-spin-dot-spin {
        height: 30px;
        width: 30px;
      }
    }

  }

  &--load-more {
    margin-top: 24px;
    &--btn {
      margin: auto;
      border: none;
      background-color: transparent;
      box-shadow: none;
    }
  }
}

@primary-color: #1178FF;@font-size-base: 16px;@border-radius-base: 20px;