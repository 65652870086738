/*************
 * Dark Mode *
 */
//////////*/
//@import '~antd/lib/style/themes/dark.less';

@import "~antd/dist/reset.css";

@import "~@animated-burgers/burger-slip/dist/styles.css";
@import "~sal.js/dist/sal.css";

@import "./variables.less";

html,
body {
  background-color: @site-background;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.spinner-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.ant-layout {
  padding-top: @header-size;
  background-color: @page-background;
  min-height: 100vh;

  @media only screen and (max-width: @header-mobile-width) {
    padding-top: @header-size-mobile;
  }
}

.ant-layout-content {
  background-color: @page-background !important;
  z-index: 2;

  a {
    color: @primary-link-color;
    text-decoration: underline;

    &:hover,
    &:active,
    &:focus {
      color: @primary-link-hover-color;
      text-decoration: underline;
    }
  }
}

// Pop confirm
.pop-confirm {
  &-confirm-btn {
    background-color: red;
    color: @white;
    padding: 4px 12px !important;
    height: auto !important;

    &:hover {
      color: @white !important;
      opacity: 0.8;
    }
  }

  &-cancel-btn {
    border: none;
    background-color: @black-5;
    color: @black;
    padding: 4px 12px !important;
    height: auto !important;

    &:hover {
      color: @black !important;
      opacity: 0.8;
    }
  }

  .ant-popover {
    &-inner {
      max-width: 274px;
      border-radius: 4px !important;
    }
  }
}

// Date range picker 
.date-range-picker {
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background-color: @black !important;
  }

  .ant-picker-cell-inner {
    &:hover {
      background: @black !important;
      background-color: @black !important;
      color: @white;
    } 
  }
  
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
  .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
    background: @black-10 !important;

    &:hover {
      background-color: @black-5;
    }
  }
}

// Scroll bar
::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb:vertical {
  height: 20px !important;
}

::-webkit-scrollbar {
  width: 8px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: @black-20;
}

@primary-color: #1178FF;@font-size-base: 16px;@border-radius-base: 20px;