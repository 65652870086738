.editor {

    .ql-editor {
        min-height: 300px;
        img {
            padding: 6px 25px 6px 25px !important;
        }
    }

    .ql-image {
        padding: 0 5px !important;
        svg {

            height: 28px !important;
            display: flex;
            align-items: center;
            justify-content: center
        }
    }

    #editor-resizer {

        .handler {
            display: none;
        }
    }
}
@primary-color: #1178FF;@font-size-base: 16px;@border-radius-base: 20px;