@import "../../styles/variables.less";

.modal-external-articles{
  max-width: 782px !important;
  width: 100% !important;
  top: 50% !important;
  transform: translateY(-50%) !important;

  .ant-modal {
    &-content {
      padding: 32px !important;
      border-radius: 4px !important;
    }

    &-body {
      margin: 24px 0;
    }

    &-footer {
      button {
        color: @black-80;

        &.ant-btn-link:not(:disabled):hover{
          color: @black !important;
        }
      }
    }
  }

  .ant-tabs-nav {
    .ant-tabs-tab {
      color: @black-80;

      &:hover {
        color: @black;
      }
    }

    .ant-tabs-ink-bar {
      background: @black-gradient;
    }
  }
}

@primary-color: #1178FF;@font-size-base: 16px;@border-radius-base: 20px;