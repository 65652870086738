@import "../../../styles/variables.less";

.post {
  background-color: #f8f8f8;

  .banner {
    position: relative;
    width: 100%;
    .export-pdf {
      margin: auto;
    }

    .image {
      position: relative;
      height: calc(60vh - 100px);
      text-align: left;
      background: linear-gradient(
        85.27deg,
        rgba(0, 0, 0, 0.8) 0%,
        rgba(45, 45, 45, 0.48) 100%
      );
      background-position: 30% 30%;
      background-size: cover;
      background-repeat: no-repeat !important;

      @media only screen and (max-width: 1440px) {
        height: calc(55vh - 100px);
      }

      @media only screen and (max-width: 768px) {
        height: calc(50vh - 100px);
      }

      .ant-col {
        display: flex;
        justify-content: center;
        max-width: 100%;

        div:nth-child(1) {
          text-align: center;
          z-index: 2;

          button {
            font-family: "Roboto", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            display: flex;
            align-items: center;
            text-align: center;
          }
        }
      }

      .banner__darken-bg {
        width: 100%;
        height: 100%;
        background-color: @banner-secondary-darken-bg-color;
        position: absolute;
        z-index: 1;
        background: linear-gradient(
          85.27deg,
          rgba(0, 0, 0, 0.8) 0%,
          rgba(45, 45, 45, 0.48) 100%
        ) !important;
      }

      @media only screen and (max-width: 850px) {
        height: calc(33vh - 100px) !important;
      }

      @media only screen and (max-width: @header-mobile-width) {
        height: calc(33vh - 100px) !important;
      }
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: left;
      padding: 0 50px;
      width: 100%;
      background-color: #777;
      background-size: cover;
      background-attachment: fixed;
      background-repeat: no-repeat;

      @media only screen and (max-width: @header-mobile-width) {
        height: calc(100vh - 100px);
        min-height: unset;
        text-align: center;
        padding: 0;
      }

      > div {
        width: 100%;

        > div {
          > div {
            width: calc(80% - 40px);
            height: auto;

            > h1 {
              margin-bottom: 32px;
              color: #fff;
              letter-spacing: 0.01em;
              font-family: "Roboto", sans-serif;
              font-style: normal;
              font-weight: 700;
              font-size: 32px;
              line-height: 38px;
              align-items: center;
              text-align: center;
              text-transform: capitalize;

              @media only screen and (max-width: @header-mobile-width) {
                font-size: 1.5em;
                line-height: 1.2em;
                margin-bottom: 20px;
              }
            }

            @media only screen and (max-width: @header-mobile-width) {
              width: 100%;
              padding: 0 20px;
            }

            > div {
              > p {
                font-size: 1.4em;
                color: #fff;

                @media only screen and (max-width: @header-mobile-width) {
                  font-size: 1.5em;
                  line-height: 1.5em;
                }
              }
            }
          }
        }

        .actions {
          position: relative;
          display: inline-block;
          padding-left: 0;
          text-align: center;

          .actions__item {
            h4 {
              font-size: 1em;
              margin-bottom: 0.1em;
            }

            p {
              font-size: 1.1em;
              margin-bottom: 0.1em;
            }

            a {
              font-size: 1em;
            }

            &:hover {
              h4 {
                color: #000000;
              }
            }
          }
        }
      }
    }
  }

  .content-main {
    margin: 50px 25%;
    padding: 0 32px;
    display: flex;
    background-color: white;
    width: 100vh;
    border-radius: @radius;

    @media only screen and (max-width: 800px) {
      padding: 0 20px;
      flex-wrap: wrap;
    }
    @media only screen and (max-width: 500px) {
      padding: 0 25px;
      flex-wrap: wrap;
      margin: 50px 11%;
    }

    .text {
      width: 100%;

      > div {
        margin: 28px 0;

        > ul {
          margin-left: 40px !important;
        }
      }

      > h1 {
        font-size: 2em;
        font-weight: normal;
        letter-spacing: 0.01em;
      }

      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
      }

      .date {
        i {
          font-family: "Inter";
          font-style: italic;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
        }
      }

      .introduction {
        p {
          margin: 0;
        }
      }

      .category {
        margin: 20px auto;
        display: flex;
        flex-direction: column;
        padding: 0px;
        gap: 16px;
        flex-wrap: wrap;

        button {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 8px 24px;
          gap: 10px;
          background-color: #ffffff;
          border: 1px solid #2d2d2d;
          border-radius: 100px;
          width: max-content;

          span {
            font-family: "Roboto", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            display: flex;
            align-items: center;
            text-align: center;
          }
        }
      }

      .image {
        height: auto;
        margin: 17px auto;
        max-width: 552px;
        max-height: 368px;

        @media only screen and (min-width: 1000px) {
          margin-bottom: 28px;
        }

        img {
          display: block;
          margin: auto;
          width: 100%;
        }

        figcaption {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          display: flex;
          justify-content: center;
        }
      }

      .content {
        padding: 0 !important;

        img {
          max-width: 100%;
        }

        p {
          word-wrap: break-word;
          font-family: Helvetica, sans-serif;
          img {
           padding: 6px 25px 6px 25px !important;
          }
        }

        // .ql-align-center {
        //     text-align: center;
        // }

        // .ql-align-right {
        //     text-align: right;
        // }

        // .ql-size-large {
        //     font-size: 1.5em;
        // }
      }
    }
  }
}

@primary-color: #1178FF;@font-size-base: 16px;@border-radius-base: 20px;