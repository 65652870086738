@import "../../../../styles/variables.less";

.post-card {
  display: flex;
  flex-direction: column;
  background-color: @white;
  border-radius: @radius;
  position: relative;
  transition: box-shadow ease-in 0.6s;
  height: 100%;

  &:hover {
    box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.25);
    transition: box-shadow ease-in 0.6s;
  }

  &--image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 228px;
    width: 100%;
    border-radius: @radius;
    position: relative;
  }

  &--tag {
    background: @white-gradient;
    color: @black;
    position: absolute;
    top: 22px;
    left: 0;
    padding: 6px 12px;
    border-radius: 0 24px 24px 0;
    box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.25);
  }

  &--body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 228px);
    padding: 24px 16px;
    text-decoration: none !important;

    &::after {
      content: "";
      height: 8px;
      width: 100%;
      background: @black-gradient;
      position: absolute;
      bottom: 0;
      left: 0;
      border-radius: 0 0 @radius @radius;
    }

    &--data {
      text-decoration: none;
      font-size: 12px;
      color: @black-80;
    }

    &--title {
      color: @black;
      font-size: 24px;
      line-height: 36px;
      margin-top: 22px;
    }

    &--description {
      color: @black;
      font-size: 14px;
      line-height: 21px;
      // height: 5em; /* 5 lines with 1em line-height */
      // overflow: hidden;
      // text-overflow: ellipsis;
    }

    &--buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 14px;

      &--pdf {
        font-size: 14px;
        color: @black;
        text-align: left;
        border: none;
        padding: 0;
        width: fit-content;
        box-shadow: none;
      }

      &--edit {
        background: @black-gradient !important;
        padding: 0;
        height: 32px;
        width: 32px;
      }
    }
  }
}

@primary-color: #1178FF;@font-size-base: 16px;@border-radius-base: 20px;