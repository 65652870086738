@import "../../../styles/variables.less";

.radar-chart {
  min-height: calc(100vh - @header-size);
  width: 100%;
  max-width: @site-max-width;
  margin: 0 auto !important;

  &--chart-col {
    overflow: hidden;
    position: relative;

    &--slider {
      position: absolute;
      bottom: 50px;
      transform: translateY(-60%);
      height: 100%;
      max-height: 300px;
      left: 2px;
      top: 50%;
      z-index: 100;
    }

    &--title {
      color: @black;
        font-size: 20px;
        font-weight: bold;
        width: 100%;
        max-width: 640px;
        margin: 16px auto 24px auto;
        text-align: center;
    }

    &--tooltip {
      padding: 24px 0;

      p {
        margin-bottom: 12px;

        span {
          font-weight: bold;
        }
      }

      hr {
        margin-bottom: 12px;
      }

      a {
        display: block;
        margin-bottom: 12px;
        font-size: 0.8rem;
        color: @black;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    // .bizcharts-tooltip {
    //   z-index: 100 !important;
    //   pointer-events: all !important;
    // }
  }

  &--filters-col {
    padding: 24px 0;

    &--filters {
      background-color: @white;
      border-radius: @radius;
      max-height: calc((90vh - @header-size));
      overflow-y: auto;

      &--title {
        color: @black;
        font-size: 24px;
        padding: 24px 24px 0 24px;
        font-weight: bold;
        position: sticky;
        top: 0;
        width: 100%;
        background-color: @white;
        display: block;
        z-index: 100;
      }

      &--form {
        padding: 0 32px 24px 32px;

        &--item {
          &--checkbox {
            line-height: 32px;
            margin-bottom: 4px;
          }

          &--select {
            &--dropdown {
              .ant-select-item-option-selected:not(
                  .ant-select-item-option-disabled
                ) {
                background-color: @black-5;
              }
            }
          }

          &--switch {
            &[aria-checked="false"] {
              background: transparent !important;
              outline: 1px solid #d9d9d9;

              .ant-switch-handle::before {
                background: @black-gradient;
              }
            }

            &[aria-checked="true"] {
              background: @black-gradient !important;
              outline: 1px solid #d9d9d9;

              .ant-switch-handle::before {
                background: @white;
              }
            }
          }

          .ant-tree-node-content-wrapper.ant-tree-node-selected {
            font-weight: bold;
            background: transparent;
          }

          label {
            color: @black-80 !important;
          }

          span {
            color: @black !important;
          }
        }
      }

      hr {
        margin: 18px 0;
      }
    }
  }
}

@primary-color: #1178FF;@font-size-base: 16px;@border-radius-base: 20px;