@import "../../../../styles/variables.less";

.filters-management {
  background-color: @white;
  padding: 24px 0;
  border-radius: @radius;
  margin-top: 32px;
  .ant-col {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .ant-row-center {
    margin: 0 20px 0 30px !important;
  }

  &--checkbox {
    line-height: 32px;
  }

  &--select--dropdown {
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: @black-5;
    }
  }

  &--switch {
    .ant-form-item-row {
      display: flex !important;
      flex-direction: inherit !important;
      justify-content: end;
    }

    .ant-form-item-control {
      max-width: 50px !important;
      &-input-content {
        text-align: end;
      }
    }

    &[aria-checked="false"] {
      background: transparent !important;
      outline: 1px solid #d9d9d9;

      .ant-switch-handle::before {
        background: @black-gradient;
      }
    }

    &[aria-checked="true"] {
      background: @black-gradient !important;
      outline: 1px solid #d9d9d9;

      .ant-switch-handle::before {
        background: @white;
      }
    }
  }

  .ant-form-item-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 24px;

    .ant-form-item-control {
      width: 100%;
    }

    .ant-form-item-label {
      label {
        color: @black-80 !important;
        text-transform: capitalize;
      }
    }
  }

  .ant-checkbox {
    span {
      color: @black !important;
    }
  }

  .ant-checkbox-inner {
    border-radius: @radius;
    width: 24px;
    height: 24px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: transparent;
  }

  .ant-checkbox-wrapper-checked
    .ant-checkbox-checked:hover
    .ant-checkbox-inner {
    background: transparent !important;
  }

  .ant-checkbox-wrapper-checked:hover
    .ant-checkbox-checked
    .ant-checkbox-inner {
    background: transparent !important;
  }

  .ant-checkbox .ant-checkbox-inner:after {
    border-color: @black;
    left: 30%;
  }

  .row-selects {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-right: 0 !important;
    padding-left: 0 !important;

    .ant-form-item-row {
      flex-direction: column;
      justify-content: end;

      .ant-form-item-control {
        max-width: 300px;
      }
    }

    .ant-col-5 {
      display: flex;
      align-items: center;
    }

    .filters-management--switch-strategic {
      display: flex;
      justify-content: center;
      margin-bottom: 0 !important;
      margin-top: 5px;
      margin-top: 5px;

      div {
        display: flex;
        align-items: center;
        flex-direction: row !important;
        margin-bottom: 0 !important;
      }
    }

    .filters-management--switch-external {
      display: flex;
      justify-content: center;
      margin-top: 5px;

      div {
        display: flex;
        align-items: center;
        flex-direction: row !important;
        margin-bottom: 0 !important;
      }
    }

    .trl {
      label {
        text-transform: initial;
      }
    }
  }

  .row-categories {
    width: 100%;
    display: flex;

    .col-categories {
      .ant-form-item-label {
        overflow: unset !important;
      }

      .ant-form-item-control {
        width: 120px;
      }
    }

    .ant-form-item-row {
      display: flex;
      flex-direction: row !important;
    }
  }
}
.ant-tooltip {
  div {
    text-align: center !important;
  }
}

@primary-color: #1178FF;@font-size-base: 16px;@border-radius-base: 20px;