@import "../../../styles/variables.less";

.posts-management {
  width: 100%;
  max-width: @site-max-width;
  margin: 64px auto !important;

  &--title {
    color: @black;
    font-size: 24px;
    font-weight: bold;
  }

  &--table {
    margin-top: 32px;

    &--paragraph {
      text-align: left;
      color: @black;
      font-size: 14px;
      line-height: 21px;

      &-center {
        text-align: center;
      }
    }

    &--actions {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0;
      padding: 0 12px;

      button {
        padding: 4px;
      }
    }

    &--switch {
      &[aria-checked="false"] {
        background: transparent !important;
        outline: 1px solid #d9d9d9;

        .ant-switch-handle::before {
          background: @black-gradient;
        }
      }

      &[aria-checked="true"] {
        background: @black-gradient !important;
        outline: 1px solid #d9d9d9;

        .ant-switch-handle::before {
          background: @white;
        }
      }
    }

    .ant-table-thead {
      tr {
        th {
          background-color: @white;
          text-align: center;
          border: none;
        }
      }
    }

    .ant-table-content {
      tbody {
        tr {
          &:nth-of-type(odd) {
            background: @black-2;
          }

          td {
            border: none;
            text-align: center;
          }
        }
      }
    }
  }
}

@primary-color: #1178FF;@font-size-base: 16px;@border-radius-base: 20px;